import store from "../store/index";

export const checkPermission = (permission) => {
  if (typeof permission === "string") {
    return store.state.user.data.permissions.includes(permission);
  } else {
    const permissions = []
    for (let i = 0; i < permission.length; i++) {
      if (store.state.user.data.permissions.includes(permission[i])) {
        permissions.push(permission[i])
      }
    }
    return permissions.length;
  }
};
